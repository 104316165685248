
import React, { ReactElement, useEffect, useState } from 'react';
import ReactMapGL, { Popup, } from 'react-map-gl';

import EventsWidget from '../components/EventsWidget';
import HydropowerPlantMarker from '../components/HydropowerPlantMarker';
import HydropowerProductionWidget from '../components/HydropowerProductionWidget';
import { HydropowerPlant } from '../service';
import { ActionTypes, useTracked } from '../state';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Popover, Row } from 'antd';

import 'mapbox-gl/dist/mapbox-gl.css';

// Styles
// @ts-ignore
import styles from './dashboard.module.scss';
import './defaults.css';

const { RangePicker } = DatePicker;
let initialLoad = true;

function Dashboard({ children, location }) {
    const TOKEN = process.env.GATSBY_MAPBOX_TOKEN;
    const [state, dispatch] = useTracked();

    // Map
    const [map, setMap] = useState<any>();
    const [hydropowerPlantMarkers, setHydropowerPlantMarkers] = useState<ReactElement[]>([]);

    // @ts-ignore
    const [view, setView] = useState<IMapViewState>({});

    const [data, setData] = useState({
        "type": "FeatureCollection",
        "features": []
    });

    useEffect(() => {
        setView(state.persisted.mapViewState);
    }, [state.persisted]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        dispatch({ type: 'GET_HYDROPOWERPLANTS' });
        dispatch({ type: 'GET_CUSTOMER' });
    };

    useEffect(() => {
        const _d = state.hydropowerplants.map((hydropowerPlant: HydropowerPlant) => {
            return (<Popup
                key={hydropowerPlant.id}
                latitude={hydropowerPlant.properties.coordinates.latitude}
                longitude={hydropowerPlant.properties.coordinates.longitude}
                closeButton={false}
                closeOnClick={false}
                captureClick={true}
                onClose={() => { }}
                anchor="left">
                <HydropowerPlantMarker hydropowerPlant={hydropowerPlant} />
            </Popup>);
        });
        setHydropowerPlantMarkers(_d);
    }, [state.hydropowerplants]);

    const handleMapEvent = (event) => {
        if (!event.isDragging && !event.iPanning && !event.isRotating && !event.isZooming) {
            if (view.latitude !== undefined) {
                dispatch({ type: ActionTypes.MAP_POSITION_CHANGE, data: { latitude: view.latitude, longitude: view.longitude, zoom: view.zoom, pitch: view.pitch, bearing: view.bearing } });
            }
        }
    };

    const renderAccount = () => {

        const content = (
            <div>
                <Button
                    type="link"
                    icon={<LogoutOutlined />}
                    onClick={() => dispatch({ type: ActionTypes.USER_LOGOUT })}
                >
                    Logg ut
                </Button>
            </div>
        );

        return (
            <Popover placement="topLeft" content={content} trigger="click">
                <Button
                    style={{ position: "fixed", zIndex: 999, marginTop: 24, right: 24, top: 0 }}
                    type="default"
                    shape="circle-outline"
                    icon={<UserOutlined />}
                    size="large" />
            </Popover>
        );
    };

    const renderWidgets = () => {
        return (
            <div style={{ bottom: 0, zIndex: 97, right: 0, left: 0, position: "fixed", padding: 6 }}>
                <Row justify="center" gutter={[16, 16]}>
                    <Col xs={24} md={10} lg={10} xl={6}><HydropowerProductionWidget customer={state.customer} /></Col>
                    <Col xs={24} md={10} lg={10} xl={6}><EventsWidget customer={state.customer} /></Col>
                </Row>
            </div>
        );
    };

    return (<>
        <ReactMapGL
            {...view}
            width="100%"
            height="100vh"
            mapStyle="mapbox://styles/bloever/ckah2my8e0r7j1imus961o353"
            mapboxApiAccessToken={TOKEN}
            onViewportChange={(viewport) => { setView(viewport); }}
            onInteractionStateChange={(event) => { handleMapEvent(event); }}
            onMouseEnter={(e) => { }}
            onLoad={(map) => {
                map.target.on('sourcedata', (map) => {
                    if (map.target.getSource('my-data') && map.target.isSourceLoaded('my-data') && initialLoad) {
                        initialLoad = false;
                    }
                });
                setMap(map.target);
            }}
        >
            {hydropowerPlantMarkers}
        </ReactMapGL>
        {renderAccount()}
        {renderWidgets()}
    </>
    );
}

export default Dashboard;

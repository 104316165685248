
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { HydropowerPlant } from "../../service";
import { KinetikRealtimePlantDataMessage } from "../../service";
import SparklinesSpot from "../General/SparklinesSpot";

import { Tag } from "antd";
import { ActionTypes, useTracked } from '../../state';

// @ts-ignore
import styles from "./styles.module.scss";


export interface IHydropowerPlantMarker {
    hydropowerPlant?: HydropowerPlant;
    x?: number;
    y?: number;
}

function HydropowerPlantMarker(props: IHydropowerPlantMarker) {

    const [latestRealtimeData, setLatestRealtimeData] = useState<KinetikRealtimePlantDataMessage>({ kinetikData: {} });
    const [state, dispatch] = useTracked();
    const [realtimeChartData, setRealtimeChartData] = useState([]);
    const [productionDescription, setProductionDescription] = useState("");
    const [showData, setShowData] = useState(false);

    useEffect(() => {
        const latest = state.hydropowerrealtimeplantdata[state.hydropowerrealtimeplantdata.length - 1];

        if (latest !== undefined) {
            if (latest.id === props.hydropowerPlant.id) {
                setShowData(true);
                setProductionDescription("KW");
                setLatestRealtimeData(latest);
                const realtimeChartData = state.hydropowerrealtimeplantdata.map((data) => { if (data.id === props.hydropowerPlant.id) { return (data.kinetikData.production); } });
                setRealtimeChartData(realtimeChartData);
            }
        }
    }, [state.hydropowerrealtimeplantdata]);

    const markerSize = 15;

    return (
        <div style={{ width: "auto", position: "fixed", top: -2 }} className={styles.pointer} onClick={() => {
            dispatch({ type: ActionTypes.SET_DASHBOARD, data: { plantDetailsActive: true, hydropowerPlant: props.hydropowerPlant } });
            navigate("/plant/" + props.hydropowerPlant.name);
        }}>
            <div style={{ width: "auto" }}>
                <div style={{ fontWeight: 400, width: "100%", color: "#FFF", fontSize: 22, textAlign: "center" }}>
                    {props.hydropowerPlant.name}
                </div>
                <div style={{ maxWidth: 80 }}>
                    {!showData ?
                        <Tag style={{ marginTop: 0, marginRight: 0, width: "100%", textAlign: "center" }} color="#E74B5E">Offline</Tag> :
                        <Tag style={{ marginTop: 0, marginRight: 0, width: "100%", textAlign: "center" }}><AnimatedNumber component="span" stepPrecision={1} value={latestRealtimeData.kinetikData.production} /*value={latestRealtimeData.kinetikData.production}*/
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 14,
                                fontWeight: 900,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                            formatValue={(n) => n.toFixed(1)}
                            duration={800}
                        /><span style={{ fontWeight: 400, marginLeft: 6 }}>{productionDescription}</span></Tag>
                    }


                </div>
                <div style={{ height: 30, maxWidth: 80, paddingTop: 8 }}>
                    <Sparklines data={realtimeChartData} limit={20} margin={12}>
                        <SparklinesLine style={{ fill: "none", strokeWidth: 4 }} color="#4BB2E7" />
                        <SparklinesSpot style={{ stroke: "#4BB2E7", strokeWidth: 6, fill: "white" }} size={10} />
                    </Sparklines>
                </div>
            </div>
        </div>
    );
}

export default HydropowerPlantMarker;
